import { useState, SyntheticEvent } from "react";
import { useLocation, Link, Outlet, useParams } from "react-router-dom";

// material-ui
import {
  Box,
  Button,
  Drawer,
  Fade,
  FormControl,
  InputLabel,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";

// assets
import {
  DocumentText,
  DollarSquare,
  Location,
  Moneys,
  Profile,
  ReceiptText,
  RouteSquare,
  Setting3,
  Setting4,
  Shield,
  ShieldCross,
  ShieldSecurity,
  Task,
  User,
  Wallet,
} from "iconsax-react";
import { StoreData } from "types/publisher";
import usePublishers from "hooks/usePublishers";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";
import { usePermissions } from "utils/permissions/usePermissions";
import RestoreReceiptsDrawer from "./RestoreReceiptsDrawer";
// ==============================|| PROFILE - ACCOUNT ||============================== //

const FinanceCenter = () => {
  const { pathname } = useLocation();

  let selectedTab = 0;
  switch (true) {
    case pathname.includes("/payouts"):
      selectedTab = 1;
      break;
    case pathname.includes("/users"):
      selectedTab = 2;
      break;

    case pathname.includes("/blocked-players"):
      selectedTab = 3;
      break;
    case pathname.includes("/configuration"):
      selectedTab = 4;
      break;
    case pathname.includes("/checkout"):
      selectedTab = 5;
      break;
  }

  const [value, setValue] = useState(selectedTab);

  const { id } = useParams();
  const { getSinglePublisher } = usePublishers(undefined, id);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (newValue === 2) {
      setValue(value);
      return;
    }
    setValue(newValue);
  };

  const [isImageLoaded, setIsImageLoaded] = useState(false); // Add state for image loading
  const [restoreReceiptsDrawerOpen, setRestoreReceiptsDrawerOpen] =
    useState(false);
  const { hasPermission } = usePermissions();

  return (
    <>
      <RestoreReceiptsDrawer
        restoreReceiptsDrawerOpen={restoreReceiptsDrawerOpen}
        setRestoreReceiptsDrawerOpen={setRestoreReceiptsDrawerOpen}
      />
      {!getSinglePublisher.isLoading && (
        <Fade key={"gametitle"} in={true} timeout={500}>
          <Stack direction="column" gap={2} mb={1}>
            <Typography variant="h3">Payments Hub</Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                {(getSinglePublisher.data as StoreData)?.storeTheme?.general
                  ?.logo && (
                  <Box
                    width={35}
                    height={35}
                    bgcolor="white"
                    borderRadius={1.5}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    p={0.5}
                  >
                    <img
                      src={getSinglePublisher?.data?.storeTheme?.general?.logo}
                      alt="logo"
                      style={{ display: isImageLoaded ? "block" : "none" }} // Show image if loaded
                      onLoad={() => setIsImageLoaded(true)} // Set state to true on load
                      onError={(e) => {
                        const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                        target.style.display = "none"; // Hide on error
                      }}
                    />
                  </Box>
                )}
                <Typography variant="h4" color="primary">
                  {getSinglePublisher?.data?.publisher?.companyName}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Fade>
      )}
      <MainCard border={true}>
        {getSinglePublisher.isLoading ? (
          <>
            <Skeleton variant="rounded" height={120} />
            <Box mt={2}></Box>
            <Stack direction="row" height={600} gap={3}>
              <Skeleton variant="rounded" height={600} width="100%" />
            </Stack>
          </>
        ) : (
          <Fade key={"gameview"} in={true} timeout={500}>
            <div>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="account profile tab"
                >
                  <Tab
                    label="Disputes"
                    component={Link}
                    to=""
                    icon={<Shield />}
                    iconPosition="start"
                    value={0}
                  />
                  <Tab
                    label="Payment Methods"
                    component={Link}
                    to={`payment-methods`}
                    icon={<Wallet />}
                    iconPosition="start"
                    disabled={
                      !hasPermission(
                        EPermissionSection.PAYOUTS,
                        EPermissionAction.GET
                      )
                    }
                  />
                  <Tab
                    label="Restore Receipts"
                    component={Link}
                    to=""
                    onClick={(e) => {
                      e.preventDefault();
                      setRestoreReceiptsDrawerOpen(true);
                    }}
                    icon={<ReceiptText />}
                    iconPosition="start"
                  />
                  <Tab
                    label="Routing Rules"
                    component={Link}
                    to={`routing-rules`}
                    icon={<Location />}
                    iconPosition="start"
                  />
                </Tabs>
              </Box>
              <Box sx={{ mt: 2.5 }}>
                <Outlet />
              </Box>
            </div>
          </Fade>
        )}
      </MainCard>
    </>
  );
};

export default FinanceCenter;
