import axios from "axios";

export const axiosServices = axios.create({
  baseURL:
    "http:" + process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL ||
    "http://localhost:3010/",
});

// Add a request interceptor
axiosServices.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const token = localStorage.getItem("__ac_token");
    if (token) {
      // Set the Authorization header
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => {
    // If the request is successful (status code 2xx)
    console.log({ response });
    return response;
  },
  (error) => {
    // If the request fails (status code 4xx or 5xx)
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 600
    ) {
      return Promise.reject(error);
    }
    return Promise.reject(error); // Fallback for any other errors
  }
);

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error.response.status);
    if (
      error.response.status === 403 &&
      !window.location.href.includes("/403")
    ) {
      localStorage.removeItem("__ac_token");
      window.location.pathname = "/login";
    }
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default axiosServices;
