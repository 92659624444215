import { useMutation, useQuery } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import { StoreData } from "types/publisher";
import axiosServices from "utils/axios";

// Fetch publishers or publishers by company ID and apply transformation only when companyId is present
const fetchPublishers = async (companyId?: string) => {
  const response = await axiosServices.get<any[]>(
    companyId
      ? `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/companies/${companyId}/projects`
      : `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/publishers`
  );

  // Only transform the data if a companyId is provided
  if (companyId) {
    const transformedData = response.data.map((publisher) => ({
      ...publisher,
      publisherSolutionModel: publisher.solutionModel,
      companyName: publisher.projectName, // Example transformation
      _id: publisher.projectId, // Example transformation
    }));

    return transformedData;
  }

  // Return raw data when companyId is not provided
  return response.data;
};

const fetchSinglePublisher = async (
  publisherId: string
): Promise<StoreData> => {
  const response = await axiosServices.get<StoreData>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/publishers/${publisherId}`
  );
  return response.data;
};

export default function usePublishers(
  companyId?: string,
  publisherId?: string
) {
  const getPublishers = useQuery({
    queryKey: [EQuery.GET_PUBLISHERS, companyId || "all"],
    queryFn: () => fetchPublishers(companyId),
  });

  const getSinglePublisher = useQuery<StoreData, Error>({
    queryKey: [EQuery.GET_SINGLE_PUBLISHER, publisherId],
    queryFn: () => fetchSinglePublisher(publisherId as string),
    enabled: !!publisherId,
  });

  const addPublisherUser = useMutation({
    mutationFn: (data: {
      id: string;
      email: string;
      name: string;
      role: string;
    }) =>
      axiosServices.post(
        `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/users/${data.id}`,
        {
          email: data.email,
          name: data.name,
          role: data.role,
        }
      ),
  });

  return { getPublishers, getSinglePublisher, addPublisherUser };
}
