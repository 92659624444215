import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import useReceipts from "hooks/useReceipts";
import { useSnackbar } from "notistack";
import { useState } from "react";
import * as Yup from "yup";

interface RestoreReceiptsDrawerProps {
  restoreReceiptsDrawerOpen: boolean;
  setRestoreReceiptsDrawerOpen: (open: boolean) => void;
}

// Add validation schema
const RestoreReceiptsSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .matches(/^[a-zA-Z0-9._-]+$/, "Invalid email format")
    .min(2, "Email must be at least 2 characters"),
  orderIds: Yup.string()
    .required("Order IDs are required")
    .test("orderIds", "Invalid order ID format", function (value) {
      if (!value) return false;
      const ids = value.split("\n");
      return ids.every((id) => /^[a-f0-9]{24}$/.test(id.trim()));
    }),
});

const RestoreReceiptsDrawer = ({
  restoreReceiptsDrawerOpen,
  setRestoreReceiptsDrawerOpen,
}: RestoreReceiptsDrawerProps) => {
  const { restoreReceipts } = useReceipts();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [enabledEmailInput, setEnabledEmailInput] = useState(false);

  const formik = useFormik({
    initialValues: {
      orderIds: "",
      email:
        localStorage.getItem("__ac_user")?.replace("@appcharge.com", "") || "",
    },
    validationSchema: RestoreReceiptsSchema,
    onSubmit: (values) => {
      restoreReceipts.mutate(
        {
          email: values.email + "@appcharge.com",
          orderIds: values.orderIds.split("\n"),
        },
        {
          onSuccess: (data) => {
            if ((data as any)?.data.failedOrders.length > 0) {
              enqueueSnackbar(
                `Failed to restore receipts for the following order IDs: ${(
                  data as any
                )?.data.failedOrders.join(", ")}`,
                {
                  variant: "error",
                  persist: true,
                  action: (key) => (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => closeSnackbar(key)}
                    >
                      Dismiss
                    </Button>
                  ),
                }
              );
            } else {
              setRestoreReceiptsDrawerOpen(false);
            }
            enqueueSnackbar("Receipts restored successfully", {
              variant: "success",
            });
          },
          onError: (err) => {
            enqueueSnackbar(`Error restoring receipts: ${err.message}`, {
              variant: "error",
            });
          },
        }
      );
    },
  });

  return (
    <Drawer
      anchor="right"
      open={restoreReceiptsDrawerOpen}
      onClose={() => setRestoreReceiptsDrawerOpen(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: { xs: "100%", sm: "75%", md: "350px" },
          padding: 3,
        },
      }}
    >
      <Typography variant="h4" whiteSpace="normal" gutterBottom sx={{ mb: 2 }}>
        Restore Receipts
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack py={1} spacing={4}>
          <Stack spacing={1} mb={-2}>
            <InputLabel
              sx={{
                whiteSpace: "pre-line",
              }}
            >
              Email Address
              <Typography
                variant="subtitle2"
                sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
              >
                The email address to send the receipts to, email must be
                associated with an AppCharge account
              </Typography>
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                {...formik.getFieldProps("email")}
                disabled={!enabledEmailInput}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                autoComplete="off"
                InputProps={{
                  endAdornment: enabledEmailInput ? (
                    <Tooltip
                      title="This email must be associated with an AppCharge account"
                      placement="left"
                      arrow
                    >
                      <Typography sx={{ color: "text.secondary" }}>
                        @appcharge.com
                      </Typography>
                    </Tooltip>
                  ) : (
                    "@appcharge.com"
                  ),
                }}
              />
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={enabledEmailInput}
                  onChange={(e) => {
                    setEnabledEmailInput(e.target.checked);
                    if (!e.target.checked) {
                      formik.setFieldValue(
                        "email",
                        localStorage
                          .getItem("__ac_user")
                          ?.replace("@appcharge.com", "")
                      );
                    }
                  }}
                />
              }
              label={
                <Typography variant="body2">
                  Use an email that isn't yours
                </Typography>
              }
            />
            <Box py={1} />
            <InputLabel
              sx={{
                whiteSpace: "pre-line",
              }}
            >
              Order IDs
              <Typography
                variant="subtitle2"
                width="100%"
                sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
              >
                For multiple receipts, separate receipt Ids with a line break
              </Typography>
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                multiline
                rows={4}
                {...formik.getFieldProps("orderIds")}
                error={
                  formik.touched.orderIds && Boolean(formik.errors.orderIds)
                }
                helperText={formik.touched.orderIds && formik.errors.orderIds}
              />
              <Typography
                variant="subtitle2"
                color="text.secondary"
                py={1}
                pl={1}
              >
                For example:
                <br />
                <code>
                  673ca3e0cf40649516080950
                  <br />
                  8f4d92b1ae5738c247159361
                  <br />
                  2c9e15f7d83a904625473182
                  <br />
                  5b1a46c9ef2085d739264093
                </code>
              </Typography>
            </FormControl>
          </Stack>
          <Stack spacing={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formik.isValid}
            >
              Restore
            </Button>
          </Stack>
        </Stack>
      </form>
    </Drawer>
  );
};

export default RestoreReceiptsDrawer;
