import { useMemo, useState } from "react";

// material-ui
import {
  Box,
  Button,
  Chip,
  Fade,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";

// third-party
import {
  useTable,
  useFilters,
  useGlobalFilter,
  Column,
  Row,
  HeaderGroup,
  Cell,
} from "react-table";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { EmptyTable } from "components/third-party/ReactTable";

import {
  GlobalFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
  renderFilterTypes,
} from "utils/react-table";
import { useNavigate } from "react-router";
import usePublishers from "hooks/usePublishers";
import { Copy, ExportSquare, More } from "iconsax-react";
import AcProgress from "components/AcProgress";
import axiosServices from "utils/axios";
import { enqueueSnackbar } from "notistack";
import Tour from "reactour";
import { usePermissions } from "utils/permissions/usePermissions";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data }: { columns: Column[]; data: [] }) {
  const navigate = useNavigate();

  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(
    () => ({ filters: [{ id: "status", value: "" }] }),
    []
  );
  const [isAddGameDrawerOpen, setIsAddGameDrawerOpen] = useState(false);
  const { hasPermission } = usePermissions();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      filterTypes,
    },
    useGlobalFilter,
    useFilters
  );

  const sortingRow = rows.slice(0, 150);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ padding: 2 }}
      >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Stack>

      <Table {...getTableProps()} size="small">
        <TableHead sx={{ borderTopWidth: 2 }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{
                    minWidth: 150,
                    overflow: "hidden",
                  }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {headerGroups.map((group: HeaderGroup<{}>) => (
            <TableRow {...group.getHeaderGroupProps()}>
              {group.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{
                    minWidth: 150,
                    overflow: "hidden",
                  }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.canFilter ? column.render("Filter") : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {sortingRow.length > 0 ? (
            sortingRow.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={() =>
                    navigate(`/publishers/${(row.original as any)._id}`)
                  }
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "action.hover" },
                  }}
                >
                  {row.cells.map((cell: Cell) => (
                    <TableCell
                      {...cell.getCellProps([
                        { className: cell.column.className },
                      ])}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <EmptyTable msg="No Data" colSpan={7} />
          )}
        </TableBody>
      </Table>
    </>
  );
}

// ==============================|| REACT TABLE - FILTERING ||============================== //

const DisputesTable = () => {
  const getCompanyFromURL = () => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const params = new URLSearchParams(url.search);
    const company = params.get("company");
    return company || undefined;
  };
  const [isFetchingCredentials, setIsFetchingCredentials] = useState(false);
  const [, setSelectedCompany] = useState("");
  const { getPublishers } = usePublishers(getCompanyFromURL());
  const [isTourOpen, setIsTourOpen] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type",

        Cell: ({ value }: { value: string }) => (
          <Chip
            label={value}
            size="small"
            variant="light"
            color={
              value === "Chargeback"
                ? "error"
                : value === "Pre-Chargeback Alert"
                ? "default"
                : "default"
            }
          />
        ),
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Payment Created",
        accessor: "paymentCreatedDate",
        Cell: ({ value }: { value: string }) =>
          new Date(value).toLocaleDateString(),
      },
      {
        Header: "Reported Date",
        accessor: "reportedDate",
        Cell: ({ value }: { value: string }) =>
          new Date(value).toLocaleDateString(),
      },
      {
        Header: "Due Date",
        accessor: "dueDate",
        Cell: ({ value }: { value: string }) =>
          new Date(value).toLocaleDateString(),
      },
      {
        Header: "Publisher",
        accessor: "publisherName",
      },
      {
        Header: "Game",
        accessor: "gameName",
      },
      {
        Header: "Order ID",
        accessor: "orderId",
        Cell: ({ value }: { value: string }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(value);
                enqueueSnackbar("Copied Order ID to clipboard", {
                  variant: "default",
                });
              }}
              size="small"
            >
              <Copy />
            </IconButton>
            <Typography>{value}</Typography>
          </Stack>
        ),
      },
      {
        Header: "Player ID",
        accessor: "playerId",
      },
      {
        Header: "Provider",
        accessor: "provider",
      },
      {
        Header: "SubMID",
        accessor: "subMid",
      },
      {
        Header: "Payment Ref",
        accessor: "providerPaymentRef",
      },
      {
        Header: "Dispute Ref",
        accessor: "providerDisputeRef",
      },
      {
        Header: "Payment Method",
        accessor: "paymentMethod",
      },
      {
        Header: "Payment Amount",
        accessor: "paymentAmount",
        Cell: ({ value, row }: { value: number; row: Row }) => (
          <Typography>{value}</Typography>
        ),
      },
      {
        Header: "Dispute Amount",
        accessor: "disputeAmount",
        Cell: ({ value, row }: { value: number; row: Row }) => (
          <Typography>{value}</Typography>
        ),
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Reason",
        accessor: "reason",
      },
      {
        Header: "Reason Code",
        accessor: "reasonCode",
      },
      {
        Header: "Publisher Purchase ID",
        accessor: "publisherPurchaseId",
      },
    ],
    []
  );

  return !getPublishers.isLoading ? (
    <>
      <Tour
        steps={[
          {
            selector: ".MuiCard-root",
            content: "This is the publishers ID",
          },
        ]}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
      {isFetchingCredentials && (
        <AcProgress
          text="Fetching credentials and logging you in..."
          isFloating={true}
        />
      )}
      {getCompanyFromURL() && (
        <Fade key={"publisherssubtitle"} in={true} timeout={500}>
          <Typography variant="h4" color="primary" my={1.5} mt={2}>
            Company: {getCompanyFromURL()}
          </Typography>
        </Fade>
      )}
      <Fade key={"publishers"} in={true} timeout={500}>
        <MainCard content={false}>
          <ScrollX>
            <ReactTable
              columns={columns as Column[]}
              data={
                [
                  {
                    type: "Pre-Chargeback Alert",
                    status: "Open",
                    paymentCreatedDate: "2024-03-01",
                    reportedDate: "2024-03-05",
                    dueDate: "2024-03-30",
                    publisherName: "Publisher 6",
                    gameName: "Titan Quest",
                    orderId: "ORD123456789",
                    playerId: "PLYR12345",
                    provider: "PaymentHub",
                    subMid: "SB123456",
                    providerPaymentRef: "PAY123456",
                    providerDisputeRef: "DSP123456",
                    paymentMethod: "Credit Card",
                    paymentAmount: 199.99,
                    disputeAmount: 199.99,
                    currency: "EUR",
                    reason: "Unauthorized Transaction",
                    reasonCode: "UA005",
                    publisherPurchaseId: "PUR123456",
                  },
                  {
                    type: "Chargeback",
                    status: "Resolved",
                    paymentCreatedDate: "2024-01-10",
                    reportedDate: "2024-01-25",
                    dueDate: "2024-02-15",
                    publisherName: "Publisher 5",
                    gameName: "Cyber Legends",
                    orderId: "ORD753159852",
                    playerId: "PLYR75315",
                    provider: "FastPay",
                    subMid: "SB753159",
                    providerPaymentRef: "PAY753159",
                    providerDisputeRef: "DSP753159",
                    paymentMethod: "Apple Pay",
                    paymentAmount: 89.99,
                    disputeAmount: 89.99,
                    currency: "CAD",
                    reason: "Duplicate Charge",
                    reasonCode: "DC004",
                    publisherPurchaseId: "PUR753159",
                  },
                  {
                    type: "Chargeback",
                    status: "Open",
                    paymentCreatedDate: "2024-01-01",
                    reportedDate: "2024-01-01",
                    dueDate: "2024-01-01",
                    publisherName: "Publisher 1",
                    gameName: "Game 1",
                    orderId: "1234567890",
                    playerId: "1234567890",
                    provider: "Provider 1",
                    subMid: "1234567890",
                    providerPaymentRef: "1234567890",
                    providerDisputeRef: "1234567890",
                    paymentMethod: "Payment Method 1",
                    paymentAmount: 100,
                    disputeAmount: 100,
                    currency: "USD",
                    reason: "Reason 1",
                    reasonCode: "Reason Code 1",
                    publisherPurchaseId: "Publisher Purchase ID 1",
                  },
                  {
                    type: "Chargeback",
                    status: "Closed",
                    paymentCreatedDate: "2024-01-20",
                    reportedDate: "2024-02-01",
                    dueDate: "2024-02-28",
                    publisherName: "Publisher 3",
                    gameName: "Dragon Quest",
                    orderId: "ORD456123789",
                    playerId: "PLYR45678",
                    provider: "SecurePay",
                    subMid: "SB789123",
                    providerPaymentRef: "PAY789123",
                    providerDisputeRef: "DSP789123",
                    paymentMethod: "PayPal",
                    paymentAmount: 59.99,
                    disputeAmount: 59.99,
                    currency: "GBP",
                    reason: "Product Not Received",
                    reasonCode: "PNR002",
                    publisherPurchaseId: "PUR456123",
                  },
                  {
                    type: "Pre-Chargeback Alert",
                    status: "Pending",
                    paymentCreatedDate: "2024-02-15",
                    reportedDate: "2024-02-20",
                    dueDate: "2024-03-15",
                    publisherName: "Publisher 2",
                    gameName: "Space Warriors",
                    orderId: "ORD789456123",
                    playerId: "PLYR98765",
                    provider: "PaymentHub",
                    subMid: "SB456789",
                    providerPaymentRef: "PAY456789",
                    providerDisputeRef: "DSP456789",
                    paymentMethod: "Credit Card",
                    paymentAmount: 249.99,
                    disputeAmount: 249.99,
                    currency: "EUR",
                    reason: "Unauthorized Transaction",
                    reasonCode: "UA001",
                    publisherPurchaseId: "PUR789456",
                  },
                  {
                    type: "Pre-Chargeback Alert",
                    status: "In Review",
                    paymentCreatedDate: "2024-02-28",
                    reportedDate: "2024-03-01",
                    dueDate: "2024-03-30",
                    publisherName: "Publisher 4",
                    gameName: "Mystic Realms",
                    orderId: "ORD159753456",
                    playerId: "PLYR15975",
                    provider: "GlobalPay",
                    subMid: "SB159753",
                    providerPaymentRef: "PAY159753",
                    providerDisputeRef: "DSP159753",
                    paymentMethod: "Debit Card",
                    paymentAmount: 149.99,
                    disputeAmount: 75.0,
                    currency: "USD",
                    reason: "Partial Refund Dispute",
                    reasonCode: "PRD003",
                    publisherPurchaseId: "PUR159753",
                  },
                ] as any
              }
            />
          </ScrollX>
        </MainCard>
      </Fade>
    </>
  ) : (
    <>
      <Skeleton variant="rounded" height={120} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
    </>
  );
};

export default DisputesTable;
