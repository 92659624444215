// material-ui
// import { useTheme } from "@mui/material/styles";

import { Stack } from "@mui/system";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({
  reverse,
  width = "120px",
  ...others
}: {
  reverse?: boolean;
  width?: string;
}) => {
  // const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="icon logo" width="100" />
     *
     */
    <>
      <Stack mt={2} justifyContent="center" alignItems="center">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 172"
          style={{ width }}
        >
          <path
            style={{
              fill: "#655ac8",
              strokeWidth: "0px",
            }}
            d="M97,75.45c-3.08-2.77-7.09-4.45-11.5-4.45-9.66,0-17.5,8.06-17.5,18s7.84,17,17.5,17c4.41,0,8.42-1.69,11.5-4.45v4.45h10v-34h-10v3.45ZM87.75,97.75c-4.83,0-8.75-4.2-8.75-9.38s3.92-9.38,8.75-9.38,8.75,4.2,8.75,9.38-3.92,9.38-8.75,9.38Z"
          />
          <path
            style={{
              fill: "#655ac8",
              strokeWidth: "0px",
            }}
            d="M314,75.45c-3.08-2.77-7.09-4.45-11.5-4.45-9.66,0-17.5,8.06-17.5,18s7.84,17,17.5,17c4.41,0,8.42-1.69,11.5-4.45v4.45h10v-34h-10v3.45ZM304.75,97.75c-4.83,0-8.75-4.2-8.75-9.38s3.92-9.38,8.75-9.38,8.75,4.2,8.75,9.38-3.92,9.38-8.75,9.38Z"
          />
          <path
            style={{
              fill: "#655ac8",
              strokeWidth: "0px",
            }}
            d="M138.5,71c-4.41,0-8.42,1.69-11.5,4.45v-3.45h-10v52h10v-22.45c3.08,2.77,7.09,4.45,11.5,4.45,9.66,0,17.5-7.06,17.5-17s-7.84-18-17.5-18ZM136.25,99c-4.83,0-8.75-4.2-8.75-9.38s3.92-9.38,8.75-9.38,8.75,4.2,8.75,9.38-3.92,9.38-8.75,9.38Z"
          />
          <path
            style={{
              fill: "#655ac8",
              strokeWidth: "0px",
            }}
            d="M227,97.17c-6.06,0-9.98-3.37-10-8.71.02-5.35,4-9.46,10-8.71,2.01.25,3.37,1.1,5,1.94l4.61-6.78c-3.14-3.02-7.63-4.33-12.11-3.92-10.47.97-17.46,7.8-17.5,17.43h0s0,.02,0,.04,0,.02,0,.04h0c.04,9.63,7.02,16.46,17.5,17.43,4.48.41,9.97-.89,13.11-3.92l-4.61-6.78c-1.63.84-3.97,1.94-6,1.94Z"
          />
          <path
            style={{
              fill: "#655ac8",
              strokeWidth: "0px",
            }}
            d="M343,75.98v-3.98h-10v34s10,0,10,0v-18.01c0-3.05,1.63-5.96,4.39-7.27.97-.46,2.02-.72,3.11-.72.6,0,2.25.2,2.91.5.99.46,1.83,1.01,2.52,1.65l4.78-9.55c-1.99-1.01-4.27-1.6-6.7-1.6-4.47,0-8.44,1.95-11,4.98Z"
          />
          <path
            style={{
              fill: "#655ac8",
              strokeWidth: "0px",
            }}
            d="M390,75.45c-3.08-2.77-7.09-4.45-11.5-4.45-9.66,0-17.5,8.06-17.5,18s7.84,18,17.5,18c4.41,0,8.42-1.69,11.5-4.45v3.23c0,5.51-4.36,10.17-9.87,10.22-.04,0-.09,0-.13,0-4.41,0-8.3-2.12-10.65-5.35l-6.7,6.7c3.52,4.56,10.19,7.64,17.86,7.64,5.47,0,9.52-1.27,12.51-3.02,5.05-2.95,6.99-8.52,6.99-14.37v-35.61h-10v3.45ZM380.75,98.75c-4.83,0-8.75-4.2-8.75-9.38s3.92-9.38,8.75-9.38c6.25,0,9.75,4.2,9.75,9.38s-3.5,9.38-9.75,9.38Z"
          />
          <path
            style={{
              fill: "#655ac8",
              strokeWidth: "0px",
            }}
            d="M438,92h5c0-6-1-21-18.5-21-10.52,0-17.46,8.02-17.5,17.92h0s0,.03,0,.04,0,.03,0,.04h0c.04,9.9,6.54,17.84,17,19,9,1,14.86-2.89,18-6l-5-6c-2,2-5,4-10,4s-10-4-10.56-8h21.56ZM416.59,85c1.13-3.53,3.9-6,8.41-6s7.28,2.87,8.2,6h-16.61Z"
          />
          <path
            style={{
              fill: "#655ac8",
              strokeWidth: "0px",
            }}
            d="M486.8,0H25.2C11.28,0,0,11.28,0,25.2v121.61c0,13.92,11.28,25.2,25.2,25.2h461.61c13.92,0,25.2-11.28,25.2-25.2V25.2c0-13.92-11.28-25.2-25.2-25.2ZM503,144.24c0,10.5-8.51,19.01-19.01,19.01H173v-61.7c3.08,2.77,7.09,4.45,11.5,4.45,9.66,0,17.5-7.06,17.5-17s-7.84-18-17.5-18c-4.41,0-8.42,1.69-11.5,4.45v-3.45h-10v91.25H28.01c-10.5,0-19.01-8.51-19.01-19.01V27.76c0-10.5,8.51-19.01,19.01-19.01h215.99v97.25s10,0,10,0v-19.01c0-3.05,1.63-5.96,4.39-7.27.97-.46,2.02-.72,3.11-.72.6,0,1.25.2,1.91.5,2.86,1.34,4.59,4.33,4.59,7.5v19h11v-23h-.05c-.55-6.71-6.58-12-13.95-12-4.47,0-8.44,1.95-11,4.98V8.75h229.99c10.5,0,19.01,8.51,19.01,19.01v116.48ZM173.5,89.62c0-5.18,3.92-9.38,8.75-9.38s8.75,4.2,8.75,9.38-3.92,9.38-8.75,9.38-8.75-4.2-8.75-9.38Z"
          />
        </svg>
        {/* <span>
          {window.location.href?.toLowerCase()?.includes("dev") ||
          window.location.href?.toLowerCase()?.includes("localhost")
            ? "DEV"
            : window.location.href?.toLowerCase()?.includes("staging")
            ? "STAGING"
            : window.location.href?.toLowerCase()?.includes("sandbox")
            ? "SANDBOX"
            : "PROD"}
        </span> */}
      </Stack>
    </>
  );
};

export default LogoMain;
