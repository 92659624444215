import { Box, Drawer, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import {
  useTable,
  usePagination,
  Column,
  Row,
  Cell,
  HeaderGroup,
} from "react-table";
import { useState, useMemo, useRef, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

// Add these components/utilities if they're custom
import MainCard from "components/MainCard";
import usePublishers from "hooks/usePublishers";
import { Publisher } from "types/publisher";

function ReactTable({ columns, data }: { columns: Column[]; data: [] }) {
  const theme = useTheme();
  const [routingRulesDrawerOpen, setRoutingRulesDrawerOpen] = useState(false);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
        },
      },
      usePagination
    );

  return (
    <>
      <RoutingRulesDrawer
        open={routingRulesDrawerOpen}
        onClose={() => setRoutingRulesDrawerOpen(false)}
      />
      <MainCard title="" content={false}>
        <Stack spacing={3}>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: HeaderGroup) => (
                    <TableCell
                      {...column.getHeaderProps([
                        { className: column.className },
                      ])}
                      style={{ width: column.cellWidth }}
                    >
                      {column.render("Header")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row: Row, i: number) => {
                prepareRow(row);
                return (
                  <TableRow
                    sx={{
                      cursor: "pointer",
                      bgcolor: row.isSelected
                        ? alpha(theme.palette.primary.lighter, 0.35)
                        : "inherit",
                    }}
                  >
                    {row.cells.map((cell: Cell) => (
                      <TableCell
                        {...cell.getCellProps([
                          { className: cell.column.className },
                        ])}
                        style={{ width: cell.column.cellWidth }}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Stack>
      </MainCard>
    </>
  );
}

const CountrySelectCell = ({
  value,
  row,
}: {
  value: Array<{ countryName: string; countryId: string }>;
  row: any;
}) => {
  const [selectedCountries, setSelectedCountries] = useState(value);
  const [searchTerm, setSearchTerm] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const options = [
    { countryName: "Israel", countryId: "1" },
    { countryName: "United States", countryId: "2" },
    { countryName: "Canada", countryId: "3" },
    { countryName: "United Kingdom", countryId: "4" },
    { countryName: "Germany", countryId: "5" },
    { countryName: "France", countryId: "6" },
  ];

  const filteredOptions = options.filter((option) =>
    option.countryName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChange = (event: any) => {
    const selectedIds = event.target.value as string[];
    const newSelectedCountries = selectedIds.map((id) => {
      const option = options.find((opt) => opt.countryId === id);
      return {
        countryId: option!.countryId,
        countryName: option!.countryName,
      };
    });

    setSelectedCountries(newSelectedCountries);
    // You might want to add a callback here to notify parent component
    // if (onChange) onChange(newSelectedCountries);
  };

  return (
    <Select
      multiple
      value={selectedCountries.map((country) => country.countryId)}
      onChange={handleChange}
      input={<OutlinedInput size="small" />}
      renderValue={(selected) =>
        selectedCountries.length > 1
          ? `${selectedCountries.length} countries selected`
          : selectedCountries[0]?.countryName || ""
      }
      sx={{ width: "100%", maxWidth: "220px", overflow: "hidden" }}
      onOpen={() => {
        setSearchTerm("");
        setIsOpen(true);
      }}
      onClose={() => setIsOpen(false)}
    >
      <Box sx={{ p: 1 }}>
        <OutlinedInput
          inputRef={searchInputRef}
          size="small"
          placeholder="Search countries..."
          fullWidth
          autoFocus
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            e.stopPropagation();
            // Prevent arrow keys from moving to options
            if (e.key === "ArrowDown" || e.key === "ArrowUp") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            e.stopPropagation();
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
        />
      </Box>
      {filteredOptions.map((option) => (
        <MenuItem key={option.countryId} value={option.countryId}>
          <Checkbox
            checked={selectedCountries.some(
              (item) => item.countryId === option.countryId
            )}
          />
          <ListItemText primary={option.countryName} />
        </MenuItem>
      ))}
    </Select>
  );
};

const PublisherSelectCell = ({
  value,
  publisherData,
  row,
}: {
  value: Array<{ gameName: string; gameId: string }>;
  publisherData: Publisher[];
  row: any;
}) => {
  const [selectedGames, setSelectedGames] = useState(value);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const groupedOptions = publisherData.reduce((acc, publisher) => {
    const companyId = publisher.companyId || "uncategorized";
    if (!acc[companyId]) {
      acc[companyId] = { companyName: publisher.companyName, publishers: [] };
    }
    acc[companyId].publishers.push({
      gameName: publisher.companyName,
      gameId: publisher._id,
    });
    return acc;
  }, {} as Record<string, { companyName: string; publishers: Array<{ gameName: string; gameId: string }> }>);

  const options = useMemo(
    () => Object.values(groupedOptions).flatMap((group) => group.publishers),
    [groupedOptions]
  );

  console.log({ options });

  const filteredOptions = options.filter((option) =>
    option.gameName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChange = (event: any) => {
    const selectedIds = event.target.value as string[];
    const newSelectedGames = selectedIds?.map((id) => {
      const option = options?.find((opt) => opt.gameId === id);
      return {
        gameId: option!.gameId,
        gameName: option!.gameName,
      };
    });

    setSelectedGames(newSelectedGames);
  };

  return (
    <Select
      multiple
      value={selectedGames.map((game) => game.gameId)}
      onChange={handleChange}
      input={<OutlinedInput size="small" />}
      renderValue={(selected) =>
        selectedGames.length > 1
          ? `${selectedGames.length} games selected`
          : selectedGames[0]?.gameName || ""
      }
      sx={{ width: "100%", maxWidth: "220px", overflow: "hidden" }}
      onOpen={() => {
        setSearchTerm("");
        setIsOpen(true);
      }}
      onClose={() => setIsOpen(false)}
    >
      <Box sx={{ p: 1 }}>
        <OutlinedInput
          inputRef={searchInputRef}
          size="small"
          placeholder="Search games..."
          fullWidth
          autoFocus
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === "ArrowDown" || e.key === "ArrowUp") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            e.stopPropagation();
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
        />
      </Box>
      {filteredOptions.map((option) => (
        <MenuItem key={option.gameId} value={option.gameId}>
          <Checkbox
            checked={selectedGames.some(
              (item) => item.gameId === option.gameId
            )}
          />
          <ListItemText primary={option.gameName} />
        </MenuItem>
      ))}
    </Select>
  );
};

const RoutingRulesDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { getPublishers } = usePublishers();

  const drawerColumns = useMemo(
    () => [
      {
        Header: "Payment Provider",
        accessor: "submid",
        cellWidth: "100px",
      },
      {
        Header: "Countries",
        accessor: "countries",
        Cell: CountrySelectCell,
        cellWidth: "100px",
      },
      {
        Header: "Games",
        accessor: "games",
        Cell: ({ value, row }: { value: any; row: any }) => (
          <PublisherSelectCell
            value={value}
            publisherData={getPublishers?.data || []}
            row={row}
          />
        ),
        cellWidth: "100px",
      },
    ],
    []
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: {
            xs: "100%",
            sm: "75%",
            md: "650px",
          },
          padding: 3,
        },
      }}
    >
      <Box>
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Add a new Rule Set
        </Typography>
        <Stack>
          <Stack py={1} spacing={4}>
            <Stack spacing={1}>
              <ReactTable
                columns={drawerColumns as any}
                data={
                  [
                    {
                      id: "1",
                      submid: "Nuvei",
                      countries: [
                        {
                          countryName: "Israel",
                          countryId: "1",
                        },
                        {
                          countryName: "United States",
                          countryId: "2",
                        },
                      ],
                      games: [
                        {
                          gameName: "Game 1",
                          gameId: "1",
                        },
                        {
                          gameName: "Game 2",
                          gameId: "2",
                        },
                      ],
                    },
                    {
                      id: "2",
                      submid: "Stripe",
                      countries: [
                        {
                          countryName: "Canada",
                          countryId: "3",
                        },
                        {
                          countryName: "United Kingdom",
                          countryId: "4",
                        },
                      ],
                      games: [
                        {
                          gameName: "Game 3",
                          gameId: "3",
                        },
                        {
                          gameName: "Game 4",
                          gameId: "4",
                        },
                      ],
                    },
                    {
                      id: "3",
                      submid: "PayPal",
                      countries: [
                        {
                          countryName: "Germany",
                          countryId: "5",
                        },
                        {
                          countryName: "France",
                          countryId: "6",
                        },
                      ],
                      games: [
                        {
                          gameName: "Game 5",
                          gameId: "5",
                        },
                        {
                          gameName: "Game 6",
                          gameId: "6",
                        },
                      ],
                    },
                  ] as any
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default RoutingRulesDrawer;
